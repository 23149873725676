@import "https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: #f2f2f2;
}
.btnn {
  background-color: #333333;
}

.about {
  color: #454545;
}
.abouts {
  color: #3a64bd;
}
/* #navbar-collapse {
  background-color: #bfb5d8;
} */

@media (min-width: 1000px) {
  .images {
    height: 500px;
    /* background-size: 100% 100%; */
  }
}

.mobile {
  -webkit-box-shadow: -7px -1px 56px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -7px -1px 56px 0px rgba(0, 0, 0, 0.75);
  box-shadow: -7px -1px 56px 0px rgba(0, 0, 0, 0.75);
}

.glow-on-hover {
  width: 220px;
  height: 50px;
  border: none;
  outline: none;
  color: #fff;
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
}

.glow-on-hover:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

.glow-on-hover:active {
  color: #000;
}

.glow-on-hover:active:after {
  background: transparent;
}

.glow-on-hover:hover:before {
  opacity: 1;
}

.glow-on-hover:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #111;
  left: 0;
  top: 0;
  border-radius: 10px;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.main-one {
  background-image: url("https://www.tek-trol.com/wp-content/uploads/2018/10/calibraton-of-flowmeter.jpeg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-blend-mode: color;
  background-color: #000000bb;
}

/* .navbar.active {
  background: linear-gradient(90deg, rgb(66, 2, 194) 0%, rgb(0, 78, 194) 100%);
} */

.navbar {
  background-color: transparent;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 999;
}

.active {
background-color: #111827;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 999;
}

.aboutme {
  font-family: "Dancing Script", cursive;
  font-weight: bolder;
}
.aboutmeee {
  font-family: "Dancing Script", cursive;
  font-weight: bolder;
}

.productet {
  background-image: url("../public/image/bull.jpg");
  background-size: cover;
  background-position: center center;
  /* filter: brightness(50%); */
}

.work {
  background-image: url("https://www.choiceiot.com/wp-content/uploads/unnamed-1-1.jpg");
  background-size: cover;
  background-position: center center;
}

.clients {
  background-color: #9cd3e8;
}

.manage {
  background-color: #bd7777;
}

.amcservice {
  background-image: url("../public/image/bhanu.png");
  /* height: 200px; */
  width: 80%;
  background-size: cover;
  background-position: center center;
}

.amcservicemain {
  /* background-image: url("../public/image/key.jpg"); */
  background-image: url("https://img.freepik.com/premium-vector/vintage-beige-scroll-paper-banner_110041-18.jpg?w=740");
  /* height: 200px; */
  /* width: 40%; */
  background-size: cover;
  background-position: center center;
}
.benefits {
  background-image: url("../public/image/benefits.png");
  background-size: cover;
  background-position: center;
}
.clientmain {
  background-image: url("https://telematicsindia.com/img/client-banner.jpg");
  background-size: cover;
  background-position: center;
}
.product {
  background-image: url("../public/image/produc.jpeg");
  background-size: cover;
  background-position: center;
  filter: blur(10px);
}
.producttow {
  background-image: url("../public/image/number.jpg");
  background-size: cover;
  background-position: center;
  filter: blur(10px);
}

@import "https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300";

div[class*="box"] {
  height: 33.33%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box-1 {
  background-color: #864545;
}

.box-11 {
  background-color: #3c3c3c;
}

.btn {
  line-height: 50px;
  height: 50px;
  text-align: center;
  width: 250px;
  cursor: pointer;
}

.btn-one {
  color: #fff;
  transition: all 0.3s;
  position: relative;
}
.btn-one span {
  transition: all 0.3s;
}
.btn-one::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-color: rgba(255, 255, 255, 0.5);
  border-bottom-color: rgba(255, 255, 255, 0.5);
  transform: scale(0.1, 1);
}
.btn-one:hover span {
  letter-spacing: 2px;
}
.btn-one:hover::before {
  opacity: 1;
  transform: scale(1, 1);
}
.btn-one::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: all 0.3s;
  background-color: rgba(255, 255, 255, 0.1);
}
.btn-one:hover::after {
  opacity: 0;
  transform: scale(0.1, 1);
}

.threep {
  background-image: url("../public/image/threeph.jpg");
  background-size: cover;
  background-position: center;
  filter: brightness(50%);
}
.singleph {
  background-image: url("../public/image/single.jpg");
  background-size: cover;
  background-position: center;
  filter: brightness(50%);
}
.dgmain {
  background-image: url("../public/image/dgmain.jpeg");
  background-size: cover;
  background-position: center;
  filter: brightness(50%);
}
.dgwifi {
  background-image: url("../public/image/dgwifi.jpg");
  background-size: cover;
  background-position: center;
  filter: brightness(50%);
}
.dgwifione {
  background-image: url("../public/image/dgmain1.avif");
  background-size: cover;
  background-position: center;
  filter: brightness(50%);
}
.dgwifitwo {
  background-image: url("../public/image/dgmain2.avif");
  background-size: cover;
  background-position: center;
  filter: brightness(50%);
}
.dgwifithree {
  background-image: url("../public/image/dgmain3.avif");
  background-size: cover;
  background-position: center;
  filter: brightness(50%);
}
.dtrh {
  background-image: url("../public/image/DTRH.jpeg");
  background-size: cover;
  background-position: center;
  filter: brightness(50%);
}
.gdmain {
  background-image: url("../public/image/dgwifi1.jpg");
  background-size: cover;
  background-position: center;
}
.tarun {
  background-image: url("../public/image/features1.jpg");
  background-size: cover;
  background-position: center;
}
.soft {
  background-image: url("../public/image/software.jpeg");
  background-size: cover;
  background-position: center;
  filter: brightness(50%);
}
.homeu {
  background-image: url("../public/image/homeunit.jpeg");
  background-size: cover;
  background-position: center;
  filter: brightness(50%);
}
.policy {
  background-image: url("../public/image/privacy.webp");
  background-size: cover;
  background-position: center;
  filter: brightness(50%);
}
.dgma {
  font-family: "Dancing Script", cursive;
  font-family: "Open Sans", sans-serif;
  font-family: "Prompt", sans-serif;
}
